import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./drop.module.scss";
import greenCircle from "../../../Assets/images/greenCircle.png";
import redCircle from "../../../Assets/images/redCircle.png";
import yellowcircle from "../../../Assets/images/yellowcircle.png";
import CommonCard from "../../Common/Ui/CommonCard/CommonCard";
import creatorimg from "../../../Assets/images/creator-img.png";
import CommonSlider from "../../Common/Ui/CommonSlider/CommonSlider";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDropDetailById } from "../../../Features/drop/dropSlice";
import { DECIMAL, PER_PAGE_RECORD_LIMIT } from "../../../Constant/userConstant";
import {
  artOfArtist,
  followUnfollowAndRemove,
  getFollowStatus,
  getNotifiedStatus,
  getNotify,
  openPopUp,
  saveNextRoute,
  setNotifyFromTop,
} from "../../../Features/user/userSlice";
import Timer from "../Timer";
import {
  determineEventStatus,
  determineEventStatusOfFCFB,
  formatDateWithTime,
  getNumberWithComma,
  sliceChar,
} from "../../../Helper/common";
import Notfound from "../../Common/NotFound/Notfound";
import CustomPagination from "../../Common/Ui/Pagination/CustomPagination";

import HoverVideoPlayer from "react-hover-video-player";
import LoadingOverlay from "../../VideoGif/LoadingOverlay";
import { checkFileType } from "../../../Helper/common";
import { Helmet } from "react-helmet";
import DOMPurify from "dompurify";

const Livedrop = () => {
  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);
  const userId = useSelector((state) => state.users.profileData?.id);
  const profileData = useSelector((state) => state?.users?.profileData);
  const targetRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isReadMoreStatus, setIsReadMoreStatus] = useState(true);
  const { id, type } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dropData, setDropData] = useState({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isFollowed, setIsFollowed] = useState("");
  const [artOfArtistList, setArtOfArtistList] = useState([]);
  const [isDisable, setIsDisable] = useState(false);
  const [buttonStatusData, setButtonStatusData] = useState();

  const Liversp = [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
      },
    },
    {
      breakpoint: 399,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
      },
    },
  ];

  // Effect to update page state based on URL query parameter
  useEffect(() => {
    if (searchParams.get("page")) {
      setPage(Number(searchParams.get("page")));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page")]);

  // Effect to fetch drop details when ID, type, page, or userId changes
  useEffect(() => {
    // Check if the type is valid; if not, navigate back
    if (
      type === "upcoming" ||
      type === "previous" ||
      type === "live" ||
      type === "all"
    ) {
      getDropDetailByIdHandler();
    } else {
      navigate(-1);
    }
  }, [id, type, page, userId]);

  const getDropDetailByIdHandler = async () => {
    try {
      let params = {
        id: id,
        type: type,
        size: PER_PAGE_RECORD_LIMIT.DIGTIAL_ART_ON_DROP_LIMIT,
        page: searchParams.get("page") > 1 ? searchParams.get("page") : page,
      };

      if (isUserLoggedin) {
        params.user_id = userId;
      }
      const response = await dispatch(getDropDetailById(params));

      setDropData(response);
      if (response.id && isUserLoggedin && type === "upcoming") {
        getNotifiedButtonStatus(response.id);
      }
      artOfArtistHandler(response.artist_id);

      if (isUserLoggedin) {
        getFollowStatusHandler(response.artist_id);
      }
      if (response.rows.length === 0) {
        if (type === "upcoming") {
          navigate(`/drop/${id}/live`);
        } else if (type === "live") {
          navigate(`/drop/${id}/previous`);
        }
      }
      setTotalPages(response.totalPages);
    } catch (err) {
      navigate(-1);
    }
  };

  // Function to determine art details based on drop type
  const getArtDetailsByDropType = (item) => {
    // Logic to determine art details based on drop type
    let data = {
      price: ``,
      subtitle: ``,
      artType: "",
      buttonTitle: "",
    };
    switch (item.drop_type) {
      case 1:
        data.price = `Floor Price : ₹${getNumberWithComma(
          Number(item.price) / DECIMAL
        )}`;
        data.subtitle = `Minimum Increment: ₹${getNumberWithComma(
          Number(item?.increment) / DECIMAL
        )}`;
        data.artType = "Auction";
        data.buttonTitle =
          type == "live" /* && profileData?.id != item?.artist_id */
            ? "Enter Auction"
            : type === "upcoming"
            ? "Get notified"
            : "";
        return data;
      case 2:
        data.price = `Minimum Increment: ₹${getNumberWithComma(
          Number(item?.increment) / DECIMAL
        )}`;
        data.subtitle = `Edition: ${getNumberWithComma(item?.no_of_editions)}`;
        data.artType = "Ranked Auction";
        data.buttonTitle =
          type === "live" /* && profileData.id !== item?.artist_id */
            ? "Enter Auction"
            : type === "upcoming"
            ? "Get notified"
            : "";
        return data;
      case 3:
        data.price = `Price : ₹${getNumberWithComma(item.price / DECIMAL)}`;
        data.subtitle =
          item?.no_of_editions - item?.sold_no_of_editions > 0
            ? `${getNumberWithComma(
                item?.no_of_editions - item?.sold_no_of_editions
              )} out of ${getNumberWithComma(item?.no_of_editions)} left`
            : `Sold out`;
        data.artType = "First Come First Buy";
        data.buttonTitle =
          type === "live" /* && profileData.id !== item?.artist_id */
            ? "Buy Now"
            : type === "upcoming"
            ? "Get notified"
            : "";
        return data;
      case 4:
        data.price = `₹${getNumberWithComma(Number(item.price) / DECIMAL)}`;
        data.artType = "Open Edition";
        data.buttonTitle =
          type === "live" /* && profileData.id !== item?.artist_id */
            ? "Buy Now"
            : type === "upcoming"
            ? "Get notified"
            : "";
        return data;
      case 5:
        data.price = `Entry Price : ₹${getNumberWithComma(
          Number(item.price) / DECIMAL
        )}`;
        data.subtitle = `Estimated Price : ₹${getNumberWithComma(
          Number(item.estimated_price) / DECIMAL
        )}`;
        data.artType = "Raffle";
        data.buttonTitle =
          type === "live" /* && profileData.id !== item?.artist_id */
            ? "Buy Now"
            : type === "upcoming"
            ? "Get notified"
            : "";
        return data;
    }
  };

  const artOfArtistHandler = async (artist_id) => {
    let params = {
      id: artist_id,
      // type: type,
    };
    try {
      const response = await dispatch(artOfArtist(params));
      setArtOfArtistList(response.data.data.list);
    } catch (error) {}
  };

  // Function to handle navigation when clicking on art
  const onclickHandler = async (item) => {
    navigate(`/digital-fine-arts/${item.id}`);
  };

  const onButtonClick = async (e, item) => {
    navigate(`/digital-fine-arts/${item.id}`);
  };

  const getNotifyButton = (e, item) => {
    e.stopPropagation();
    getNotifiedHandler(e, item, "ART");
  };

  // Function to truncate description based on read more status
  const getDescriptionByIsreadStatus = (description, length) => {
    // Logic to truncate description
    if (isReadMoreStatus === true) {
      return `${sliceChar(description, length)}....`;
    } else {
      return `${description}`;
    }
  };

  const sanitizedContent = (content) => {
    let dropContent = !isReadMoreStatus
      ? content
      : getDescriptionByIsreadStatus(dropData?.description, 200);
    return { __html: DOMPurify.sanitize(dropContent) };
  };

  // Function to handle follow/unfollow the artist
  const followUnfollowHandler = async (type) => {
    try {
      let data = {
        type: type,
        id: dropData.artist_id,
      };
      await dispatch(followUnfollowAndRemove(data));
      getFollowStatusHandler();
    } catch (error) {}
  };

  // get the follow status of artist isfollowed or not
  const getFollowStatusHandler = async (id) => {
    let params = {
      following_id: id ? id : dropData.artist_id,
    };
    try {
      const response = await dispatch(getFollowStatus(params));
      setIsFollowed(response.data.data.is_followed);
    } catch (error) {}
  };

  // Function to scroll to top
  const handleScroll = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ top: 0, behavior: "smooth" });
    }
  };

  const getImageSection = (item) => {
    // Determine the file type of the feature file
    let fileType = checkFileType(
      `${process.env.REACT_APP_IMG_BASE_URL}${item?.feature_file}`
    );
    // Check if the feature file is a video (MP4 or OGG)
    if (fileType == "MP4" || fileType == "OGG") {
      // Return JSX for displaying video
      return (
        <div className="video_showing_tsxt">
          <HoverVideoPlayer
            loop={true}
            restartOnPaused
            unloadVideoOnPaused={true}
            disableRemotePlayback={false}
            paused={true}
            muted={true}
            className={`video_hover_sec ${styles.image_sec}`}
            videoSrc={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_file}`}
            onClick={() => onclickHandler(item)}
            pausedOverlay={
              <CommonCard
                className={styles.card_img}
                starttime={
                  type === "live" && item.drop_type != 3
                    ? "Ends in : "
                    : type === "upcoming"
                    ? "Starts in : "
                    : ""
                }
                waittime={
                  type === "live" && item.drop_type != 3 ? (
                    <Timer startingTimestamp={item?.end_time} />
                  ) : type === "upcoming" ? (
                    <Timer startingTimestamp={item?.start_time} />
                  ) : (
                    ""
                  )
                }
                imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item?.thumbnail_image}`}
                title={item.art_name}
                subtitle={`@${item.username}`}
                bottomclass="align-items-center"
                bottomTitle={getArtDetailsByDropType(item)?.price}
                bottomSubTitle={getArtDetailsByDropType(item).subtitle}
                linkAction={`${getArtDetailsByDropType(item)?.buttonTitle}`}
                onButtonClick={
                  type === "live"
                    ? (e) => onButtonClick(e, item)
                    : type === "upcoming"
                    ? (e) => getNotifyButton(e, item)
                    : () => {}
                }
                item={item}
                is_Disable={isDisable}
                buttonStatusData={buttonStatusData}
              />
            }
            loadingOverlay={<LoadingOverlay />}
          />
        </div>
      );
    } else {
      // Return JSX for displaying image
      return (
        <CommonCard
          className={styles.card_img}
          starttime={
            type === "live" && item.drop_type != 3
              ? "Ends in : "
              : type === "upcoming"
              ? "Starts in :"
              : ""
          }
          waittime={
            type === "live" && item.drop_type != 3 ? (
              <Timer startingTimestamp={item?.end_time} />
            ) : type === "upcoming" ? (
              <Timer startingTimestamp={item?.start_time} />
            ) : (
              ""
            )
          }
          imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item?.thumbnail_image}`}
          featureUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_file}`}
          onClick={() => onclickHandler(item)}
          title={item.art_name}
          subtitle={`@${item.username}`}
          bottomclass="align-items-center"
          bottomTitle={getArtDetailsByDropType(item)?.price}
          bottomSubTitle={getArtDetailsByDropType(item).subtitle}
          linkAction={`${getArtDetailsByDropType(item)?.buttonTitle}`}
          onButtonClick={
            type === "live"
              ? (e) => onButtonClick(e, item)
              : type === "upcoming"
              ? (e) => getNotifyButton(e, item)
              : () => {}
          }
          item={item}
          is_Disable={isDisable}
          buttonStatusData={buttonStatusData}
        />
      );
    }
  };

  const getImageSectionBrowseMarket = (item) => {
    // Determine the file type of the feature file
    let fileType = checkFileType(
      `${process.env.REACT_APP_IMG_BASE_URL}${item?.feature_file}`
    );
    // Check if the feature file is a video (MP4 or OGG)
    if (fileType == "MP4" || fileType == "OGG") {
      // Return JSX for displaying video
      return (
        <div className="video_showing_tsxt">
          <HoverVideoPlayer
            loop={true}
            restartOnPaused
            unloadVideoOnPaused={true}
            disableRemotePlayback={false}
            paused={true}
            muted={false}
            onClick={() => onclickHandler(item)}
            className={`video_hover_sec ${styles.image_sec}`}
            videoSrc={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_file}`}
            pausedOverlay={
              <CommonCard
                type={"video"}
                imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.thumbnail_image}`}
                to={item.to}
                title={item.art_name}
                subtitle={`@${dropData.artist_name}`}
                item={{ artist_id: dropData.artist_id }}
                onClick={() => onclickHandler(item)}
              />
            }
            loadingOverlay={<LoadingOverlay />}
          />
        </div>
      );
    } else {
      return (
        <CommonCard
          className={styles.cardhover}
          imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.thumbnail_image}`}
          featureUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_file}`}
          to={item.to}
          title={item.art_name}
          subtitle={`@${dropData.artist_name}`}
          item={{ artist_id: dropData.artist_id }}
          onClick={() => onclickHandler(item)}
        />
      );
    }
  };

  const getNotifiedHandler = async (e, item, eventType) => {
    if (isUserLoggedin) {
      try {
        let data = {
          event_type: eventType,
          status: 1,
        };
        if (eventType === "DROP" || eventType === "ART") {
          data.id = item.id;
        }
        // return
        const response = await dispatch(getNotify(data));
        if (response?.status == 200) {
          getDropDetailByIdHandler();
          await getNotifiedButtonStatus();
        }
      } catch (err) {}
    } else {
      dispatch(saveNextRoute(`/curated-drop/digital-art/${type}/${id}`));
      navigate("/login");
    }
  };

  const getNotifiedButtonStatus = async (dropId) => {
    try {
      let params = {
        id: dropId ? dropId : dropData.id,
      };
      let response = await dispatch(getNotifiedStatus(params));
      const check = response.data == 1 ? true : false;
      setIsDisable(check);
      dispatch(setNotifyFromTop(response.data == 1 ? true : false));
      setButtonStatusData(response.data);
    } catch (err) {}
  };

  return (
    <>
      <section className={styles.liveDrop}>
        <Helmet>
          <title>NewArtX</title>
          <meta name="description" content="" />
        </Helmet>

        <div className={styles.liveDrop_topsection}>
          <div className={styles.user_upimg}>
            <img
              src={`${process.env.REACT_APP_IMG_BASE_URL}${dropData?.cover_image}`}
              alt="LiveDropbanner"
            />
          </div>
          <div className={styles.headingtext}>
            <h2 title={dropData.drop_name}>
              {sliceChar(dropData.drop_name, 20)}
            </h2>
            {type === "live" && (
              <div className={styles.live_minted}>
                <p>Dropped On : {formatDateWithTime(dropData.createdAt)}</p>
                <h5>
                  LIVE <img src={greenCircle} alt="greenCircle" />
                </h5>
              </div>
            )}
            {type === "upcoming" && (
              <div className={styles.comingsoon}>
                <div className={styles.comingsoon__textfile}>
                  <label>
                    Coming Soon <img src={yellowcircle} alt="yellowcircle" />
                  </label>
                  <p>Dropped On : {formatDateWithTime(dropData.createdAt)}</p>
                </div>
                <button
                  className={styles.getnotifyBtn}
                  onClick={(e) => getNotifiedHandler(e, dropData, "DROP")}
                  disabled={isDisable}
                >
                  Get Notified
                </button>
              </div>
            )}

            {type === "previous" && (
              <div className={styles.live_minted}>
                <p>Dropped On : {formatDateWithTime(dropData.createdAt)}</p>
                <h5>
                  PREVIOUS <img src={redCircle} alt="redCircle" />
                </h5>
              </div>
            )}
          </div>
          <div className={styles.liveDrop_topsection_info} ref={targetRef}>
            {dropData?.description && (
              <>
                <div
                  className="textSection text-white"
                  dangerouslySetInnerHTML={sanitizedContent(
                    dropData?.description
                  )}
                ></div>
                {dropData?.description.length > 200 && (
                  <>
                    {isReadMoreStatus ? (
                      <button
                        className={styles.readBtn}
                        onClick={() => setIsReadMoreStatus((prev) => !prev)}
                      >
                        Read more
                      </button>
                    ) : (
                      <button
                        className={styles.readBtn}
                        onClick={() => setIsReadMoreStatus((prev) => !prev)}
                      >
                        Show less
                      </button>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <div className={styles.art_cardsec}>
          <Container>
            <Row>
              {dropData?.rows?.length > 0 &&
                dropData?.rows?.map((item, index) => (
                  <Col sm={6} lg={4} xl={3} key={index} className="mt-5">
                    <h6 className={styles.artcar_value}>{`${
                      getArtDetailsByDropType(item)?.artType
                    }`}</h6>

                    {getImageSection(item)}
                  </Col>
                ))}
            </Row>
            {dropData?.rows?.length === 0 && (
              <Notfound className="py-5" text={"No digital art found."} />
            )}
            {totalPages > 1 && (
              <CustomPagination
                className="ms-auto"
                activePage={page}
                totalPage={totalPages}
                setActivePage={setPage}
                setSearchParams={setSearchParams}
                maxVisiblePages={5}
                handleScroll={handleScroll}
              />
            )}
          </Container>
        </div>
        <div className={styles.browse_section}>
          <div className={styles.browse_section__topfollow}>
            <img
              src={
                dropData?.image_path
                  ? `${process.env.REACT_APP_IMG_BASE_URL}${dropData?.image_path}`
                  : creatorimg
              }
              alt="creatorimg"
              className={styles.userIcon}
            />
            <h5>@{dropData?.artist_name}</h5>
            <div
              className="textSection"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(dropData?.bio),
              }}
            ></div>
            {userId != dropData.artist_id && (
              <>
                {isUserLoggedin && (
                  <>
                    {isFollowed === 0 ? (
                      <button
                        className={styles.followBtn}
                        onClick={() => followUnfollowHandler(1)}
                      >
                        Follow
                      </button>
                    ) : (
                      <button
                        className={styles.followBtn}
                        onClick={() => followUnfollowHandler(0)}
                      >
                        Unfollow
                      </button>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <Container>
            <h2 className={styles.browsetitle}>
              Browse @{dropData?.artist_name} Art
            </h2>
            <ul className={styles.brwose_card}>
              <CommonSlider
                className={artOfArtistList.length === 0 ? "no_found_data" : ""}
                dots={false}
                slidesToShow={5}
                arrows={artOfArtistList?.length > 5 ? true : false}
                slidesToScroll={1}
                responsive={Liversp}
                infinite={artOfArtistList?.length > 5 ? true : false}
                autoplay={true}
                pauseOnHover={true}
              >
                {artOfArtistList.map((item, index) => (
                  <li className={styles.cardlist}>
                    {getImageSectionBrowseMarket(item)}
                  </li>
                ))}
                {artOfArtistList.length === 0 && (
                  <Notfound
                    className={`my-5 ${styles.notFound}`}
                    text={"No digital art found."}
                  />
                )}
              </CommonSlider>
            </ul>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Livedrop;
