import React, { useEffect, useRef, useState } from "react";
import style from "./Activedigitalart.module.scss";
import { Col, Row } from "react-bootstrap";
import CommonCard from "../../../Common/Ui/CommonCard/CommonCard";
import { useDispatch, useSelector } from "react-redux";
import { getArts } from "../../../../Features/drop/dropSlice";
import {
  DECIMAL,
  PER_PAGE_RECORD_LIMIT,
} from "../../../../Constant/userConstant";
import CustomPagination from "../../../Common/Ui/Pagination/CustomPagination";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  checkFileType,
  determineEventStatus,
  determineEventStatusOfFCFB,
  getNumberWithComma,
} from "../../../../Helper/common";
import Notfound from "../../../Common/NotFound/Notfound";
import HoverVideoPlayer from "react-hover-video-player";
import styles from "../../../Private/CuratedDropDigitalArt/CuratedDropDigitalArt.module.scss";
import LoadingOverlay from "../../../VideoGif/LoadingOverlay";

const Activedigitalart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ArtistId = useSelector((state) => state?.users?.profileData?.id);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [art, setArt] = useState([]);
  const elementToScroll = useRef(null);

  useEffect(() => {
    getUserCreatedArts();
  }, [ArtistId, page]);

  useEffect(() => {
    if (searchParams.get("page")) {
      setPage(Number(searchParams.get("page")));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page")]);

  const scrollToElement = () => {
    // Scroll to the element
    elementToScroll.current.scrollIntoView({ behavior: "smooth" });
  };

  const getUserCreatedArts = async () => {
    try {
      let params = {
        id: ArtistId,
        size: PER_PAGE_RECORD_LIMIT.ART_CREATED_LIMIT,
        page: searchParams.get("page") > 1 ? searchParams.get("page") : page,
      };
      const response = await dispatch(getArts(params));
      setArt(response.data.data.arts);
      setTotalPages(response.data.data.totalPages);
      scrollToElement();
    } catch (err) {}
  };

  const naviagteToArtDetail = async (e, item) => {
    navigate(`/digital-fine-arts/${item.id}`);
  };

  const getImageSectionGif = (item) => {
    let type = checkFileType(
      `${process.env.REACT_APP_IMG_BASE_URL}${item?.feature_file}`
    );
    if (type == "MP4" || type == "OGG") {
      return (
        <HoverVideoPlayer
          loop={true}
          restartOnPaused
          unloadVideoOnPaused={true}
          disableRemotePlayback={false}
          paused={true}
          // muted={false}
          muted={true}
          // onClick={() => onclickHandler(item)}
          onClick={(e) => naviagteToArtDetail(e, item)}
          className={`video_hover_sec ${styles.image_sec}`}
          videoSrc={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_file}`}
          pausedOverlay={
            <CommonCard
              // key={index}
              imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.thumbnail_image}`}
              title={item.art_name}
              subtitle={``}
              bottomTitle={`Price: ₹ ${getNumberWithComma(
                Number(item.price) / DECIMAL
              )}`}
              bottomSubTitle={
                item.drop_type != 4
                  ? `${item.no_of_editions - item.sold_no_of_editions} / ${
                      item.no_of_editions
                    }`
                  : ""
              }
              item={item}
              onClick={(e) => naviagteToArtDetail(e, item)}
            />
          }
          loadingOverlay={<LoadingOverlay />}
        />
      );
    } else {
      return (
        <CommonCard
          imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.thumbnail_image}`}
          featureUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_file}`}
          title={item.art_name}
          subtitle={``}
          bottomTitle={`Price: ₹ ${getNumberWithComma(
            Number(item.price) / DECIMAL
          )}`}
          bottomSubTitle={
            item.drop_type != 4
              ? `${item.no_of_editions - item.sold_no_of_editions} / ${
                  item.no_of_editions
                }`
              : ""
          }
          item={item}
          onClick={(e) => naviagteToArtDetail(e, item)}
        />
      );
    }
  };

  return (
    <>
      <section className={style.active_digitalart} ref={elementToScroll}>
        <div className={style.card_colloection}>
          <Row>
            {art.map((item, index) => (
              <Col xl={3} lg={4} md={6} key={index} className="cursor-pointer">
                {getImageSectionGif(item)}
              </Col>
            ))}
            {art.length === 0 && <Notfound text={"No digital art found."} />}
          </Row>
        </div>

        {totalPages > 1 && (
          <CustomPagination
            className="ms-auto"
            activePage={page}
            totalPage={totalPages}
            setActivePage={setPage}
            maxVisiblePages={5}
            setSearchParams={setSearchParams}
          />
        )}
      </section>
    </>
  );
};

export default Activedigitalart;
