import React, { useState } from "react";
import CommonTable from "../../../../Common/Ui/CommonTable/CommonTable";
import style from "../index_dashboard.module.scss";
import CommonBtn from "../../../../Common/Ui/CommonBtn/CommonBtn";
import { SearchIcon } from "../../../../../Assets/svgIcons/SVGicon";
import CustomPagination from "../../../../Common/Ui/Pagination/CustomPagination";
import {
  determineEventStatus,
  determineEventStatusOfFCFB,
  formatDateWithTime,
  getNumberWithComma,
  sliceChar,
} from "../../../../../Helper/common";
import { DECIMAL } from "../../../../../Constant/userConstant";
import PersonIcon from "../../../../../Assets/images/person_icon.jpg";
import Notfound from "../../../../Common/NotFound/Notfound";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { downloadInvoiceAction } from "../../../../../Features/user/userSlice";
import InvoiceTemplate from "./InvoiceTemplate/InvoiceTemplate";
import { RoutesUrls } from "../../../../../Constant/RoutesUrls";

export default function Purchased(props) {
  const [showinvoice, setShowinvoice] = useState(false);
  const [invoiceContent, setInvoiceContent] = useState({});
  const navigate = useNavigate();

  let { totalPage, page, setPage, data, setSearch } = props;

  const checkNavigation = (username) => {
    navigate(`/profile/${username}`);
  };

  const dispatch = useDispatch();

  const downloadInvoiceFunction = async (order_id) => {
    try {
      let response = await dispatch(downloadInvoiceAction(order_id));
      let data = response.data.data;
      setInvoiceContent(data);
      setShowinvoice(true);
    } catch (err) {
      console.log("error in catch", err);
    }
  };

  const artNavigation = (item) => {
    navigate(`/digital-fine-arts/${item.art_id}`);
  };

  return (
    <>
      <div className={style.purchased_tab}>
        <div className={style.searchbar}>
          <div className="form">
            <>
              <div className="search_input mb-5">
                <div className="search_input_inner">
                  <button type="submit" className="search_input_icon">
                    <SearchIcon />
                  </button>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search Art, Artist "
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </>
          </div>
        </div>
        <div className={style.table_section}>
          <CommonTable className={style.artowned_table}>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="field_table">
                      <img
                        src={
                          item?.thumbnail_image
                            ? `${process.env.REACT_APP_IMG_BASE_URL}${item?.thumbnail_image}`
                            : PersonIcon
                        }
                        alt="artimage"
                        onClick={() => artNavigation(item)}
                        className="cursor-pointer"
                      />
                      <div className="text_field cursor-pointer">
                        <h5
                          title={item.art_name}
                          onClick={() => artNavigation(item)}
                        >
                          {sliceChar(item.art_name, 10)}
                        </h5>
                        <p
                          title={item.artist_username}
                          onClick={() => checkNavigation(item?.artist_username)}
                        >
                          @{sliceChar(item.artist_username, 15)}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="Secfield_table">
                      <img
                        src={
                          item?.from_user_image
                            ? `${process.env.REACT_APP_IMG_BASE_URL}${item?.from_user_image}`
                            : PersonIcon
                        }
                        alt="artimage"
                      />
                      <div className="text_field_all">
                        <h6>Purchased From</h6>
                        <p
                          className="cursor-pointer"
                          title={item.from_account_username}
                          onClick={() =>
                            checkNavigation(item?.from_account_username)
                          }
                        >
                          @{sliceChar(item.from_account_username, 15)}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="text_field_all">
                      <h6>Purchased Price</h6>
                      {item.total_art_price_inc_tax >= 0 ? (
                        <p>
                          ₹{" "}
                          {getNumberWithComma(
                            (
                              Number(item.total_art_price_inc_tax) / DECIMAL
                            ).toFixed(2)
                          )}{" "}
                        </p>
                      ) : (
                        <p>--</p>
                      )}
                    </div>
                  </td>

                  <td>
                    <div className="text_field_all">
                      <h6>Purchase Date</h6>
                      <p>{formatDateWithTime(item.createdAt)}</p>
                    </div>
                  </td>
                  {/* <td>
                    <div className="btn_section">
                      <CommonBtn
                        role="btn"
                        className={`bordred-black me-5 ${style.btneditsize}`}
                        title="Get Invoice"
                        onClick={() => downloadInvoiceFunction(item.order_id)}
                      /> */}

                  {/* <CommonBtn
                        role="btn"
                        className={`black-btn-yellow ${style.btneditsize}`}
                        title="Purchase History"
                      /> */}
                  {/* </div>
                  </td> */}
                </tr>
              ))}
              {data.length === 0 && <Notfound text={"No record found."} />}
            </tbody>
          </CommonTable>
        </div>
        {totalPage > 1 && (
          <CustomPagination
            className="ms-auto"
            activePage={page}
            totalPage={totalPage}
            // setActivePage={setPage}
            maxVisiblePages={5}
            dispatch={dispatch}
            componentType={"Transaction"}
          />
        )}
      </div>
      {showinvoice && (
        <InvoiceTemplate
          show={showinvoice}
          handleClose={() => setShowinvoice(false)}
          data={invoiceContent}
        />
      )}
    </>
  );
}
