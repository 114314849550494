import React, { useEffect, useRef, useState } from "react";
import style from "./Activecollection.module.scss";
import { Col, Row } from "react-bootstrap";
import CommonCard from "../../../Common/Ui/CommonCard/CommonCard";
import { getDrops } from "../../../../Features/drop/dropSlice";
import { useDispatch, useSelector } from "react-redux";
import { PER_PAGE_RECORD_LIMIT } from "../../../../Constant/userConstant";
import { useNavigate, useSearchParams } from "react-router-dom";
import Notfound from "../../../Common/NotFound/Notfound";
import CustomPagination from "../../../Common/Ui/Pagination/CustomPagination";

const Activecollection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ArtistId = useSelector((state) => state?.users?.profileData?.id);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [drops, setDrops] = useState([]);
  const elementToScroll = useRef(null);

  useEffect(() => {
    Drops();
  }, [ArtistId, page]);

  useEffect(() => {
    if (searchParams.get("page")) {
      setPage(Number(searchParams.get("page")));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page")]);

  const naviagteToDropDetail = async (e, item) => {
    navigate(`/drop/${item.id}/all`);
  };

  const scrollToElement = () => {
    // Scroll to the element
    elementToScroll.current.scrollIntoView({ behavior: "smooth" });
  };

  const Drops = async () => {
    try {
      let params = {
        id: ArtistId,
        size: PER_PAGE_RECORD_LIMIT.ART_CREATED_LIMIT,
        page: searchParams.get("page") > 1 ? searchParams.get("page") : page,
      };

      const response = await dispatch(getDrops(params));
      setDrops(response.data.data.drops);
      setTotalPages(response.data.data.totalPages);
      scrollToElement();
    } catch (err) {}
  };

  return (
    <>
      <section className={style.active_collection} ref={elementToScroll}>
        <div className={style.card_colloection}>
          <Row>
            {drops.map((item, index) => (
              <Col xl={3} lg={4} md={6}>
                <CommonCard
                  className={`card_withouthover`}
                  key={index}
                  imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.cover_image}`}
                  title={item.drop_name}
                  bottomTitle={`Digital Art :${item.art_count}`}
                  // bottomSubTitle=
                  onClick={(e) => naviagteToDropDetail(e, item)}
                />
              </Col>
            ))}
            {drops.length === 0 && <Notfound text={"No drop found."} />}
          </Row>
        </div>
        {totalPages > 1 && (
          <CustomPagination
            className="ms-auto"
            activePage={page}
            totalPage={totalPages}
            setActivePage={setPage}
            setSearchParams={setSearchParams}
            maxVisiblePages={5}
          />
        )}
      </section>
    </>
  );
};

export default Activecollection;
