import React, { useCallback, useEffect, useRef, useState } from "react";
import CommonBtn from "../../../../Common/Ui/CommonBtn/CommonBtn";
import collectionbg from "../../../../../Assets/images/collection-bg.jpg";
import Slider from "react-slick";
import style from "./ArtImagesleft.module.scss";
import { Dropdown } from "react-bootstrap";
import Commondropdown from "../../../../Common/Ui/CommonDropdown/Commondropdown";
import {
  checkFeatureImageRuleBase,
  checkFileType,
  sliceChar,
} from "../../../../../Helper/common";
import ClickExpendModal from "../../../NewArtPage/Component/ClickExpendModal/ClickExpendModal";
import { selectDevice } from "../../../../../Features/linkedDevice/linkedDeviceSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import videoIcon from "../../../../../Assets/images/videoicon.png";
import { store } from "../../../../../App/store";
import { ORIENTATION } from "../../../../../Constant/userConstant";

const ArtImagesleft = ({
  portrait,
  dfaDetail,
  getDeviceDetailListHandler,
  deviceList,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);
  const [clickShow, setClickShow] = useState(false);
  const mediaRef = useRef(null);
  const [selectedFeatureImageVideo, setSelectedFeatureImageVideo] = useState(
    {}
  );
  const [muted, setMuted] = useState(true); 

  const settingsLandscape = {
    infinite: true,
    arrow: true,
    speed: 500,
    // className: "center",
    // centerMode: true,
    // centerPadding: "0",
    slidesToShow: Math.min(5, dfaDetail?.art_images?.length),
    slidesToScroll: 1,
  };
  const settingsPortrait = {
    dots: false,
    infinite: true,
    arrow: true,
    // className: "center",
    // centerMode: true,
    // centerPadding: "0",
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  useEffect(() => {
    if (Object.keys(dfaDetail).length > 0) {
      setSelectedFeatureImageVideo(dfaDetail?.art_images[0]);
    }
  }, [dfaDetail]);

  // Function to enter full screen
  const openFullScreen = () => {
    // Create a new media element dynamically
    let mediaType = checkFileType(dfaDetail?.art_image);
    if (Object.keys(dfaDetail?.orientationMedia)?.length) {
      if (store?.getState()?.users?.orientation === ORIENTATION.LANDSCAPE) {
        mediaType = checkFileType(
          `${process.env.REACT_APP_IMG_BASE_URL}${dfaDetail?.orientationMedia?.landscape}`
        );
      } else {
        mediaType = checkFileType(
          `${process.env.REACT_APP_IMG_BASE_URL}${dfaDetail?.orientationMedia?.portrait}`
        );
      }
    }
    let mediaElement;
    if (mediaType === "MP4" || mediaType === "OGG") {
      mediaElement = document.createElement("video");
      mediaElement.src = checkFeatureImageRuleBase(dfaDetail);
      mediaElement.controls = true; // Add controls if needed
      mediaElement.autoplay = true; // Enable autoplay
      mediaElement.muted = true; // Mute the video to allow autoplay
      mediaElement.loop = true;
    } else {
      mediaElement = document.createElement("img");
      mediaElement.src = checkFeatureImageRuleBase(dfaDetail);
      mediaElement.alt = "Full Screen Image";
    }

    // Style the media element for full screen (optional)
    mediaElement.style.width = "100%";
    mediaElement.style.height = "100%";
    mediaElement.style.objectFit = "contain";

    // Append the media element to the body (to make it available for full-screen)
    document.body.appendChild(mediaElement);
    mediaRef.current = mediaElement; // Keep a reference to remove it later

    // Enter full-screen mode
    if (mediaElement.requestFullscreen) {
      mediaElement.requestFullscreen();
    } else if (mediaElement.webkitRequestFullscreen) {
      // Safari support
      mediaElement.webkitRequestFullscreen();
    } else if (mediaElement.msRequestFullscreen) {
      // IE/Edge support
      mediaElement.msRequestFullscreen();
    }

    // Handle full-screen exit to remove the media element
    mediaElement.onfullscreenchange = () => {
      if (!document.fullscreenElement) {
        // Full-screen mode exited, remove the media element from the DOM
        document.body.removeChild(mediaElement);
      }
    };
  };

  const getFetureFileSection = (src) => {
    let type = checkFileType(src);
    if (type == "MP4" || type == "OGG") {
      return (
        <video
          loop
          autoPlay
          muted={muted}
          controlsList="nodownload"
          ref={mediaRef}
          key={src}
          onMouseEnter={() => setMuted(false)}  // Unmute on hover
          onMouseLeave={() => setMuted(true)} 
        >
          <source src={src} type="video/mp4" />
        </video>
      );
    } else {
      return <img src={src} alt="Dfa-Image" ref={mediaRef} />;
    }
  };

  const getSource = useCallback((src, data) => {
    let type = checkFileType(src);
    return (
      <img
        onClick={() => setSelectedFeatureImageVideo(data)}
        src={type === "MP4" || type === "OGG" ? videoIcon : src}
        alt="artist_nft"
      />
    );
  }, []);

  const handleDeviceOnChangeHanlder = async (device) => {
    //0 means unselected 1 means selected
    try {
      let data = {
        device_id: device.device_id,
        is_play_on_device: device.is_art_played === 1 ? 0 : 1,
      };
      let params = {
        art_id: id,
      };
      const response = await dispatch(selectDevice(data, params));
      if (response) {
        getDeviceDetailListHandler(false);
      }
    } catch (error) {
      console.log("error in catch", error);
    }
  };

  return (
    <>
      <div className={style.artImages}>
        {portrait === 0 ? (
          <div className={style.landscapeImg}>
            <div
              className={`${style.imageSec} cursor-pointer`}
              onClick={() => {
                openFullScreen("landscape");
              }}
            >
              {getFetureFileSection(
                `${process.env.REACT_APP_IMG_BASE_URL}${selectedFeatureImageVideo?.img_file}`
              )}
              <CommonBtn
                className={`bg-border-without ${style.expandBtn}`}
                title="Click to Expand"
                role="btn"
              />
            </div>
            {dfaDetail?.art_images?.length > 1 && (
              <div className={style.sliderSec}>
                <Slider {...settingsLandscape}>
                  {dfaDetail?.art_images?.map((data) => (
                    <div>
                      {getSource(
                        `${process.env.REACT_APP_IMG_BASE_URL}${data.img_file}`,
                        data
                      )}
                    </div>
                  ))}
                </Slider>
              </div>
            )}
            <div className={style.tagFilter}>
              <ul className={style.listing}>
                {isUserLoggedin && (
                  <li>
                    <Commondropdown
                      dropdowntitle="Preview on Device"
                      classdrop={style.dropDown}
                    >
                      {deviceList?.length === 0 && (
                        <Dropdown.Item>No Device Found.</Dropdown.Item>
                      )}
                      {deviceList?.map((device) => (
                        <Dropdown.Item
                          className={device?.is_art_played && `active`}
                          onClick={() => handleDeviceOnChangeHanlder(device)}
                          title={device?.device_name}
                        >
                          {sliceChar(device?.device_name, 20)}
                        </Dropdown.Item>
                      ))}
                    </Commondropdown>
                  </li>
                )}
                {dfaDetail?.tags?.map((item, index) => (
                  <li>
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : (
          <div className={style.portraitImg}>
            <div
              className={`${style.imageSec} cursor-pointer`}
              onClick={() => {
                openFullScreen("portrait");
              }}
            >
              {getFetureFileSection(
                `${process.env.REACT_APP_IMG_BASE_URL}${selectedFeatureImageVideo?.img_file}`
              )}
              <CommonBtn
                className={`bg-border-without ${style.expandBtn}`}
                title="Click to Expand"
                role="btn"
              />
            </div>
            {dfaDetail?.art_images?.length > 1 && (
              <div className={style.sliderSec}>
                <Slider {...settingsPortrait}>
                  {dfaDetail?.art_images?.map((data) => (
                    <div>
                      {getSource(
                        `${process.env.REACT_APP_IMG_BASE_URL}${data.img_file}`,
                        data
                      )}
                    </div>
                  ))}
                </Slider>
              </div>
            )}
            <div className={style.tagFilter}>
              {isUserLoggedin && (
                <Commondropdown
                  dropdowntitle="Preview on Device"
                  classdrop={style.dropDown}
                >
                  {deviceList?.length === 0 && (
                    <Dropdown.Item>No Device Found.</Dropdown.Item>
                  )}
                  {deviceList?.map((device) => (
                    <Dropdown.Item
                      className={device?.is_art_played && `active`}
                      onClick={() => handleDeviceOnChangeHanlder(device)}
                      title={device?.device_name}
                    >
                      {sliceChar(device?.device_name, 15)}
                    </Dropdown.Item>
                  ))}
                </Commondropdown>
              )}
              <ul className={style.listing}>
                {dfaDetail?.tags?.map((item, index) => (
                  <li>
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      <ClickExpendModal
        show={clickShow}
        handleClose={() => setClickShow(false)}
        imageActive={true}
      />
    </>
  );
};

export default ArtImagesleft;
